exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".indicator-module-root{align-content:center;align-items:center;display:grid;gap:2rem;height:calc(100% - 6rem);justify-content:center;justify-items:center;padding:3rem 0;width:100%}.indicator-module-root .image-container{display:flex;justify-content:center}.indicator-module-global{position:fixed;top:0;left:0;margin:0;height:100%;width:100%;z-index:9999;background-color:#ede5d5}.indicator-module-message{font-display:auto;font-family:\"Knockout-73FullHeviweight\";font-weight:bold;font-size:13px;line-height:16px;letter-spacing:.04em;font-weight:700;text-transform:uppercase;color:#992631;padding:0 !important}@keyframes indicator-module-fade{0%{opacity:0}50%{opacity:1}100%{opacity:0}}.indicator-module-indicator{animation-direction:alternate;animation-duration:2.5s;animation-iteration-count:infinite;animation-name:indicator-module-fade;animation-timing-function:linear;padding:0 !important}.indicator-module-indicator img{border-radius:0 !important}", ""]);

// Exports
exports.locals = {
	"root": "indicator-module-root",
	"global": "indicator-module-global indicator-module-root",
	"message": "indicator-module-message",
	"indicator": "indicator-module-indicator",
	"fade": "indicator-module-fade"
};